const OTPAUTH_PROTOCOL = "otpauth://";
const ISSUER_PARAM = "issuer";
const SECRET_PARAM = "secret";

function parseOtpAuth(data){
    let result = {};
    result.isOTP = data.startsWith(OTPAUTH_PROTOCOL);
    if(!result.isOTP){
        return result;
    }
    result.issuer = getSearchParam(ISSUER_PARAM);
    result.secret = getSearchParam(SECRET_PARAM);

    function getSearchParam(param){
        let startOfValue = data.indexOf(param) + param.length + 1; //startofValueofParam (mind the =)
        if(startOfValue == -1)
            return null;
        let endOfValue = data.indexOf("&", startOfValue);
        endOfValue = endOfValue == -1 ? data.length : endOfValue;
        return data.slice(startOfValue,endOfValue);
    }

    return result;
}

export default parseOtpAuth;