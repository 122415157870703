<template>
  <div>
    <div>
        <div style="display: flex;"
          ><h3 class="headline mb-0">{{ site }}</h3>
        </div>
        <div style="display:flex;"
          ><h3>{{ token }}</h3></div
        >
      <div style="display: flex">
        <button  @click="copySecretToClipboard">Generate and Copy</button>
        <button  @click="deleteSecret">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import {authenticator} from "otplib";
import copy from "clipboard-copy";

export default {
  name: "OTP",
  props: ["secret", "site", "id"],
  data() {
    return {
      token: "------",
    };
  },
  methods: {
    generateSecret() {
      this.token = authenticator.generate(this.secret);
    },
    deleteSecret() {
      this.$emit("delete-secret", { id: this.id });
    },
    copySecretToClipboard() {
      this.generateSecret();
      copy(this.token);
    },
  },
};
</script>

<style>
</style>
