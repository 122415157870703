<template>
  <div>
      <button @click="openDialog()">Scan QR</button>
    <!--v-dialog
      v-model="dialog"
      fullscreen
    >
    <v-card style="background-color:white;">
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="closeDialog()">
                  <v-icon>close</v-icon>
                </v-btn>
              </v-toolbar>
      <v-responsive>
      <canvas ref="canvas" hidden></canvas>
      <video ref="video" id="video"></video>
      </v-responsive>
    </v-card>
    </v-dialog-->
  </div>
</template>

<script>
    import jsQR from 'jsqr';

export default {
  data(){
      return {
          canvasElement: null,
          stream: null,
          code: null,
          dialog: false
      }
  },
  props: ['visible'],
  methods: {
    tick() {
      if (this.$refs.video.readyState === this.$refs.video.HAVE_ENOUGH_DATA) {
        this.$refs.canvas.height = this.$refs.video.videoHeight;
        this.$refs.canvas.width = this.$refs.video.videoWidth;
        this.canvas.drawImage(this.$refs.video, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        var imageData = this.canvas.getImageData(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        var code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
            this.code = code.data;
            this.$emit('qr-scanned', code.data);
            this.closeDialog();
            return;
        }
      }
      requestAnimationFrame( () => this.tick());
    },
    closeDialog(){
            this.stream.getTracks()[0].stop();
            this.$refs.video.hidden = true;
            this.dialog = false;
    },
    openDialog(){
      this.dialog = true; 
      this.canvas = this.$refs.canvas.getContext("2d");
      this.stream = null;

      navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then((_stream) => {
      this.stream = _stream;
      this.$refs.video.srcObject = _stream;
      this.$refs.video.setAttribute("playsinline", true);
      this.$refs.video.play();
      this.$refs.video.hidden = false;
      requestAnimationFrame(() => this.tick());
    });        
    }

  }
};
</script>

<style>

</style>
