<template>
  <div class="app" id="app">
    <div class="app__toolbar">
      <h1>OTPWA</h1>
    </div>
    <div class="app__main">
      <div class="app_cheappw" v-if="secrets.length == 0">
        <form @submit.prevent="start" style="display:grid; grid-template-columns: 1fr 1fr; grid-gap:10px; grid-auto-rows: 1fr; width:320px; margin: 10px auto; align-items: stretch">
          <label style="display: flex; align-items: center;" for="username">
            <span>Username</span>
          </label>
          <input type="text" name="username" id="username">
          <label style="display: flex; align-items: center;" for="password">
            Password
          </label>
          <input type="password" name="password" id="password">
          <button style="grid-column: 1/-1;">Go</button>
        </form>
      </div>
      <div class="app__grid" v-else>
        <otp
          v-for="secret in secrets"
          :key="secret.id"
          v-bind="secret"
          v-on:delete-secret="deleteSecret"
        ></otp>
      </div>
      <qr-scanner v-on:qr-scanned="qrScanned"></qr-scanner>
    </div>
  </div>
</template>

<script>
import Otp from "./components/Otp.vue";
import QrScanner from "./components/QrScanner.vue";
import parseOTP from "./utils/parseOTPUrl.js";
import PasswordCrypto from "password-crypto";

const SECRET_KEY  ="OTPWA_SECRET";

export default {
  name: "app",
  data() {
    return {
      secrets: [],
      dbServer: process.env.VUE_APP_DBSERVER,
      password: null
    };
  },
  methods: {
    qrScanned(event) {
      let result = parseOTP(event);
      if (result.isOTP) {
        this.postSecret(result.issuer, result.secret)
          .then((resp) => resp.json())
          .then((rjson) => {
            this.addSecret(result.issuer, result.secret, rjson.id, rjson.rev);
          });
      }
    },
    addSecret(site, secret, id, rev) {
      this.secrets.push({
        site,
        secret,
        id,
        rev,
      });
    },
    deleteSecret({ id }) {
      let index = this.secrets.findIndex((ele) => ele.id == id);
      let secret = this.secrets[index];

      this.secrets.splice(index, 1);
      deleteRemote.call(this);

      function deleteRemote() {
        fetch(`${this.dbServer}/otpwa/${secret.id}?rev=${secret.rev}`, {
          method: "DELETE",
          credentials: "include",
          mode: "cors",
        });
      }
    },
    postSecret(issuer, secret) {
      return fetch(this.dbServer + "/otpwa/", {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        credentials: "include",
        mode: "cors",
        body: JSON.stringify({ issuer, secret }),
      });
    },
    async start(event) {
      let pass = event.target.elements.password.value;
      const headers = new Headers();
      headers.set("Authorization", 'Basic ' + btoa(event.target.elements.username.value + ":" + pass));
      try {
        let response = await fetch(this.dbServer + "/otpwa/_all_docs?include_docs=true", {
          credentials: "include",
          headers
        });
        let secrets = await response.json();
        if(secrets.total_rows === undefined){
          throw Error("Not an expected response, aborting...");
        }
        window.localStorage.setItem(SECRET_KEY, await PasswordCrypto.encryptMessage(JSON.stringify(secrets), pass));
        getSecrets.call(this, secrets);
      }catch(err){
        console.log("Couldn't get secrets, try to use cache...");
        let secrets;
        if((secrets = window.localStorage.getItem(SECRET_KEY)) != undefined){
          getSecrets.call(this, JSON.parse(await PasswordCrypto.decryptMessage(secrets, pass)));
          console.log("Success");
        }else{
          console.log("No secrets cached, sorry :(");
        }
      }

      function getSecrets(jsons) {
        jsons.rows.forEach((ele) => {
          this.addSecret(
            ele.doc.issuer,
            ele.doc.secret,
            ele.doc._id,
            ele.doc._rev
          );
        });
      }
    },
  },
  components: {
    Otp,
    QrScanner,
  },
};
</script>

<style>
body {
  padding: 0px;
  margin: 0px;
}

body {
  box-sizing: border-box !important;
}

#app {
  text-align: center;
  color: #2c3e50;
}

.app .app__toolbar {
  display: flex;
  padding: 0px 5px;
  background-color: cornflowerblue;
  align-items: center;
}

.app .app__grid {
  display: grid;
  padding: 0px 5px;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: 1fr;
}
</style>
